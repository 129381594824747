<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          ">
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.my_patients") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
      </div>
      <div class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          ">
        <export-excel v-can="'patients.excel'" class="btn excel_btn" :data="list" :fields="excel_fields"
          @fetch="controlExcelData()" worksheet="Заказы" name="Заказы.xls">
          <el-button size="mini" icon="el-icon-document-delete">Excel</el-button>
        </export-excel>
        <crm-column-settings :columns="columns" :modelName="'doctorCabinet'"
          @c-change="updateColumn"></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.patient_id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.day" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.service" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.doctor" :sort="sort" @c-change="updateSort"></crm-th>

          <crm-th :sort="sort" :column="columns.created_at" @c-change="updateSort"></crm-th>
          <crm-th :sort="sort" :column="columns.settings" @c-change="updateSort"></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" class="id_input" v-model="filterForm.id"
              :placeholder="columns.id.title"></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input size="mini" v-model="filterForm.patient_id" :placeholder="columns.patient_id.title"
              clearable></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker v-model="filterForm.day" :placeholder="columns.day.title" size="mini" :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"></el-date-picker>
          </th>
          <th v-if="columns.service.show">
            <!-- <select-service-name-inventory
                v-model="filterForm.service_id"
                :placeholder="columns.service.title"
                size="mini"
              >
              </select-service-name-inventory> -->
            <el-input size="mini" v-model="filterForm.service_name" :placeholder="columns.service.title" clearable>
            </el-input>
          </th>
          <th v-if="columns.doctor.show">
            <el-select filterable clearable :placeholder="columns.doctor.title" size="mini"
              v-model="filterForm.doctor_id">
              <el-option v-for="item in users" :key="item.id" :label="item.surname + ' ' + item.name"
                :value="item.id"></el-option>
            </el-select>
          </th>


          <th v-if="columns.created_at.show">
            <el-date-picker :format="'dd.MM.yyyy'" :value-format="'dd.MM.yyyy'" v-model="filterForm.created_at"
              :placeholder="columns.created_at.title" size="mini"></el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="patient in list" :key="patient.id" v-on:dblclick="show(patient.id)" style="cursor: pointer">
          <td v-if="columns.id.show">{{ patient.id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              patient.patient && patient.patient.surname
              ? patient.patient.surname
              : ""
            }}
            {{ patient.patient ? patient.patient.first_name : "" }}
          </td>
          <td v-if="columns.day.show">{{ patient.day }}</td>
          <td v-if="columns.service.show">
            <p v-for="(item, index) in patient.services" :key="index" class="p-0 m-0">
              {{ item ? item.name : "" }}
            </p>
          </td>
          <td v-if="columns.doctor.show">
            {{
              patient.doctor
              ? patient.doctor.surname + " " + patient.doctor.name
              : ""
            }}
          </td>

          <td v-if="columns.created_at.show">
            {{ patient.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <div class="za_btn">
              <el-button type="success">
                <router-link style="color: #fff" :to="{
                  name: 'patientLaboratoryConclusions',
                  params: { id: patient.id },
                }">{{ $t("message.show_0") }}</router-link>
              </el-button>
              <el-button @click="dialogOpen(patient)" type="primary" icon="el-icon-edit">{{ $t("message.conclusions")
              }}</el-button>
              <el-button @click="returnBack(patient)" icon="el-icon-close"></el-button>
            </div>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-dialog title=" Выберите шаблон " :append-to-body="true" :visible.sync="outerVisible" width="40%">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input clearable :placeholder="$t('message.search_template')" v-model="filterText"></el-input>
          <el-tree class="mt-2 filter-tree" :data="data" node-key="id" :filter-node-method="filterNode" ref="tree"
            @node-click="handleNodeClick" :expand-on-click-node="true" show-checkbox>
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>

    <el-drawer :visible.sync="drawerCreateCoculation" :wrapperClosable="false" size="100%" :drawer="drawerCreateCoculation"
      @open="reopenUpdate = true" @close="reloadIfChanged('reopenUpdate')" @closed="emptyModel"
      @opened="drawerOpened('createDrawer')">
      <div>
        <crm-create :reloadModel="reopenUpdate" @open="reopenUpdate = true" @c-close="closeDrawer"
          ref="createDrawer"></crm-create>
      </div>
    </el-drawer>

   
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createConclusion";
import drawer from "@/utils/mixins/drawer";
import list from "@/utils/mixins/list";
import axios from "axios";
import { i18n } from "@/utils/i18n";
import Inventory from "@/components/inventory-select/select-service_name-inventory";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    CrmCreate,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      drawerUpdateCoculation: false,
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      selected_patient: [],
      backUrl: process.env.VUE_APP_URL_DOCS,
      outerVisible: false,
      model: null,
      data: null,
      filterText: "",
    };
  },
  computed: {
    ...mapGetters({
      list: "laboratoryCabinet/patient_list",
      columns: "laboratoryCabinet/columns",
      pagination: "laboratoryCabinet/pagination",
      filter: "laboratoryCabinet/filter",
      sort: "laboratoryCabinet/sort",
      conditions: "conditions/list",
      users: "users/inventory_general",
      token: "auth/token",
    }),
  },
  async created() { },
  async mounted() {
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
   if (this.users && this.users.length === 0) await this.getUsers();
    this.categoryDocumentationList({ relation: true })
      .then((res) => {
        this.data = res.data.category_forms;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    ...mapActions({
      updateList: "laboratoryCabinet/patientLaboratoryList",
      showModel: "orders/relationShow",
      updateSort: "laboratoryCabinet/updateSort",
      updateFilter: "laboratoryCabinet/updateFilter",
      categoryDocumentationList: "categoryDocumentation/index",
      updateColumn: "laboratoryCabinet/updateColumn",
      updatePagination: "laboratoryCabinet/updatePagination",
      empty: "laboratoryCabinet/empty",
      returnInformalLaborant: "laboratoryCabinet/returnInformalLaborant",
      refreshData: "laboratoryCabinet/refreshData",
      loadConditions: "conditions/iventory",
      getUsers: "users/inventory_general",
    }),
    returnBack(patient) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.returnInformalLaborant(patient.id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message:  i18n.t("message.operation_canceled"),
          });
        });
    },
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    dialogClose() {
      this.outerVisible = false;
      this.form = {};
      this.model = null;
    },
    tset() {
      var name =
        model.id + " " + model.patient.surname + " " + model.patient.first_name;

      axios({
        url: this.backUrl + "/check/" + name,
        method: "POST",
        data: formData,
      });
    },
    dialogOpen(model) {
      this.model = model;
      var name =
        model.id + " " + model.patient.surname + " " + model.patient.first_name;

      axios({
        url: this.backUrl + "/check/" + name,
        method: "GET",
      })
        .then((res) => {
          if (res.data == true) this.createModal(this.model, null);
          else this.outerVisible = true;
        })
        .catch((err) => {
          this.outerVisible = true;
        });
    },
    handleNodeClick() { },

    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();
      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }
      this.createModal(this.model, template_id);
    },
    refresh() {
      this.refreshData();
    },
    async createModal(model, template_id) {
      var name =
        model.id + " " + model.patient.surname + " " + model.patient.first_name;
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
        "/file_orientation?order_id=" +
        model.id +
        "&template_id=" +
        template_id +
        "&name=" +
        name +
        "&user=" +
        this.token +
        "&status=create" +
        "&user_type=laborant"
      );
      link.setAttribute("target", "_blank");
      link.click();

      this.dialogClose();

      return;
      this.$loadingCursor("wait");
      const query = { id: id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.drawerCreateCoculation = true;
          this.$loadingCursor("default");
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;

    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



